<template>
<div :style="computedStyle" v-show="!isHidden" v-loading="loading">
  {{value}}
  <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
    <i class="el-icon-question"></i>
  </el-tooltip>
  <slot></slot>
</div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'

export default {
  name: 'a-label',
  mixins: [mixin, VisibleMixin],
  inject: ['isEditor', 'getModel'],
  props: {
    text: {
      type: String,
      description: 'Текст по умолчанию',
      default: 'Текст'
    },
    size: {
      type: String,
      description: 'Размер шрифта'
    },
    align: {
      type: String,
      description: 'Выравнивание'
    },
    margin: {
      type: String,
      description: 'Отступы',
      default: '5px 10px'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    weight: {
      type: Boolean,
      description: 'Жирный',
      default: false
    },
    source: {
      type: Object,
      editor: 'Source',
      default: () => {
        return {
          type: null,
          entityId: null,
          valueField: {}
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      value: null
    }
  },
  watch: {
    dataFilters () {
      this.loadData()
    },
    text (val) {
      this.value = val
    },
    variableValue () {
      this.loadData()
    }
  },
  async mounted () {
    this.value = this.text
    await this.loadData()
  },
  methods: {
    async loadData () {
      if (this.source.type === 'extended_object' && this.source.entityId && this.source.valueField.id && !this.isEditor()) {
        this.loading = true
        // let data = await this.$http.get(`${this.$config.api}/datawarehouseservice/extended_object/${this.source.entityId}?${this.dataFilters.join('&')}`)
        let data = await this.$http.post(`${this.$config.api}/datawarehouseservice/extended_object/${this.source.entityId}`, this.dataFilters, {
          hideNotification: true
        })
          .finally(() => {
            this.loading = false
          })
        data = data.data[0]
        if (data) {
          this.value = data[this.source.valueField.name] || this.text
        } else {
          this.value = this.text
        }
      } else if (this.source.type === 'variable' && this.source.valueField.name) {
        this.value = this.variableValue
      }
    }
  },
  computed: {
    variableValue () {
      if (this.source.type === 'variable' && this.source.valueField.name) {
        return this.getModel()[this.source.valueField.name]
      }
      return null
    },
    dataFilters () {
      let filters = {
        'where': {
          'and': []
        }
      }
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = item.isXref ? 'equals_any' : 'eq'
          let object = {}
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              object[type] = {}
              object[type][item.alias] = this.getModel()[item.attribute]
              filters.where.and.push(object)
              // filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            object[type] = {}
            object[type][item.alias] = item.attribute
            // filters.push(`${item.alias}=${item.attribute}`)
            filters.where.and.push(object)
          } else if (item.type === 'current_user') {
            object[type] = {}
            object[type][item.alias] = this.$store.getters['Authorization/userId']
            // filters.push(`${item.alias}=${this.$store.getters['Authorization/userId']}`)
            filters.where.and.push(object)
          }
        })
      }
      return filters
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.size) {
        css += ';font-size: ' + this.size
      }
      if (this.weight) {
        css += ';font-weight: bold'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
